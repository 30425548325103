/****
import React,{useState, useEffect} from 'react'
import { Link, useParams,  useLocation,  useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col, Table } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'

import { register, registerAll, logout, getUserDetails, getUserDetailsAll, updateUserProfile, updateUserAllProfile} from '../actions/userActions'
import {USER_UPDATE_RESET} from '../constants/userConstants'

import { listMyOrders } from '../actions/orderActions'

import validator from 'validator'

function ProfileScreen() {
    const  [name, setName] = useState('')
    const  [email, setEmail] = useState('')
    const  [celular, setCelular] = useState('')
    const  [ciudad, setCiudad] = useState('')
    const  [localidad, setLocalidad] = useState('')
    const  [barrio, setBarrio] = useState('')
    const  [direccion, setDireccion] = useState('')
    const  [password, setPassword] = useState('')
    const  [confirmPassword, setConfirmPassword] = useState('')
    const  [message, setMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const validate = (value) => {  
        if (validator.isStrongPassword(value, {
          minLength: 8, minLowercase: 1,
          minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
          setErrorMessage('')
        } else {
          setErrorMessage('No es una contraseña segura. Longitud minima 8, conteniendo al menos  1 número, 1 mayúscula, 1 minuscula y un simbolo.')
        }
      }
      
    const dispatch = useDispatch()
    const dispatchAll = useDispatch()
  
  
    const match = useParams()
    const Location = useLocation()
    const navigate = useNavigate()
  
   
    const userDetails = useSelector(state => state.userDetails)
    const { error, loading, user} = userDetails

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo} = userLogin  

    const userUpdateProfile = useSelector(state => state.userLogin)
    const { success } = userUpdateProfile  


    const userAllLogin = useSelector(state => state.userAllLogin)
    const { userInfoAll} = userAllLogin 
  
    const orderListMy = useSelector(state => state.orderListMy)
    const { loading:loadingOrders, error:errorOrders, orders } = orderListMy  
  
    useEffect(() => {
     if(!userInfo){
       navigate('/login')
     }else{

        const userInfoAllString = localStorage.getItem("userInfoAll");
    
        
            const userInfoAll = JSON.parse(userInfoAllString);
            dispatch({type:USER_UPDATE_RESET})
            dispatch(getUserDetails(userInfoAll.email)) 
        
            //console.log(userInfo.email)
            //dispatch(loginAll(email)) 
  
      // dispatch(registerAll( name, email, celular, ciudad, barrio, localidad, direccion))      
      // dispatch(logout())
      // window.location.replace('https://registro.tucolonia.co/')
     }
    },[navigate, userInfo,  dispatch, user, success] )
  
     useEffect(() => {
        if(userInfoA){
         
         navigate(redirect)
        }
       },[navigate, userInfo, redirect, userInfoA] ) 
  
       const logoutHandler = () => {
        dispatch(logout())
        window.location.reload()
    }
    const submitHandler = (e) => {
      e.preventDefault()
      if(password != confirmPassword){
        setMessage("Las contraseñas no coinciden")
      }else{
        dispatch(updateUserProfile({
                                   'id':user._id, 
                                   'name': name,
                                   'email':email,
                                   'password': password
                                   }))

        dispatch(updateUserAllProfile({
                                        'id':userInfoAll[0]._id, 
                                        'name': name,
                                        'email':email,
                                        'celular': celular,
                                        'ciudad': ciudad,
                                        'barrio': barrio,
                                        'localidad': localidad,
                                        'direccion': direccion
        }, user.email))
        dispatch({type:USER_UPDATE_RESET})
        dispatch(getUserDetails('profile'))     
        window.location.reload(true);
        setMessage("")
      }      
      
    }


  return (
    <Row>
        <Col md ={3}>
            <h2>Perfil de Usuario</h2>
            {message && <Message variant='danger'> {message} </Message>}
        {error && <Message variant='danger'> {error} </Message>}
        {loading && <Loader />}
      

      <Form onSubmit={submitHandler}>

        <Form.Group controlId='name'>
            <Form.Label>🧑 Nombre Completo</Form.Label>
            <Form.Control 
                required
                type='name'
                placeholder='Ingresa tu Nombre Completo'    
                value = {name}
                onChange = {(e) => setName(e.target.value)}
            >
            </Form.Control>
        </Form.Group>
        <Form.Group controlId='email'>
            <Form.Label>📧 E-mail </Form.Label>
            <Form.Control 
                required
                type='email'
                placeholder='Ingresa tu E-mail'    
                value = {email}
                onChange = {(e) => setEmail(e.target.value)}
            >
            </Form.Control>
        </Form.Group>
        <Form.Group controlId='celular'>
            <Form.Label>📱 Número de celular</Form.Label>
            <Form.Control 
                required
                type= 'number'
                placeholder='Ingresa tu número de celular'    
                value = {celular}
                onChange = {(e) => setCelular(e.target.value)}
            >
            </Form.Control>
        </Form.Group>
        <Form.Group controlId='ciudad'>
              <Form.Label>🌆 Ciudad</Form.Label>
            <Form.Control 
                required
                type= 'ciudad'
                placeholder='Ingresa tu ciudad'    
                value = {ciudad}
                onChange = {(e) => setCiudad(e.target.value)}
            >
            </Form.Control>
        </Form.Group>
        <Form.Group controlId='direccion'>
            <Form.Label>🏢 Dirección</Form.Label>
            <Form.Control 
                required
                type= 'direccion'
                placeholder='Ingresa tu direccion'    
                value = {direccion}
                onChange = {(e) => setDireccion(e.target.value)}
                >
            </Form.Control>
        </Form.Group>
        <Form.Group controlId='localidad'>
            <Form.Label> 🏛 Localidad</Form.Label>
            <Form.Control 
                type= 'localidad'
                placeholder='Ingresa tu localidad'    
                value = {localidad}
                onChange = {(e) => setLocalidad(e.target.value)}
                >
            </Form.Control>
        </Form.Group>
        <Form.Group controlId='barrio'>
            <Form.Label>🏬 Barrio</Form.Label>
            <Form.Control                 
                type= 'barrio'
                placeholder='Ingresa tu barrio'    
                value = {barrio}
                onChange = {(e) => setBarrio(e.target.value)}
                >
            </Form.Control>
        </Form.Group>
        
        <Form.Group controlId='password'>
            <Form.Label>🔏 Contraseña </Form.Label>
            <Form.Control 
                type='password'
                placeholder='Ingresa tu contraseña'    
                value = {password}
                onChange={e => { validate(e.target.value) ; setPassword(e.target.value) }}
            >
            </Form.Control>
        </Form.Group>
        <span style={{
          fontWeight: 'bold',
          color: 'red',
        }}>{errorMessage}</span>
        <br></br>
        <Form.Group controlId='passwordConfirm'>
            <Form.Label>🔏 Confirmar Contraseña </Form.Label>
            <Form.Control 
                type='password'
                placeholder='Confirma tu contraseña'    
                value = {confirmPassword}
                onChange = {(e) => setConfirmPassword(e.target.value)}
            >
            </Form.Control>
        </Form.Group>
        
        <Row className='py-3'>
            <Col>
                Al registrarte aceptas nuestros  <Link to='/terminos'>
                Términos y condiciones📑
                    </Link>  y nuestra <Link to='/politica'>Política de Datos</Link> 
            </Col>
        </Row>
        <Button type='submit' variant='primary'>
            Actualiza tus datos 🐜
        </Button>

        <Button variant='primary' onClick={logoutHandler}>
            Cerrar Sesión
        </Button>

      
      </Form>
        </Col>
       
    </Row>
  )
}

export default ProfileScreen
*/
import styled from 'styled-components';
import { TextParagraphNormal, TextTitle, TitleSection } from '../components/texts';
import { ButtonCommon } from '../components/buttons';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Colors } from '../styles/global';
import React, { useRef, useEffect, useState } from "react";
import { register, registerAll, logout, getUserDetails, getUserDetailsAll, updateUserProfile, updateUserAllProfile } from '../actions/userActions'
import '../styles/profile.css';


const ContainerMain = styled.div`
  max-width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

`;

const ContainerProfile = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 20px;
  background-color: ${Colors.tertiary};
  border-radius: 20px;
  margin-top: 150px;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    width: 90%;
    padding: 10px;
  }
`;

const TitleCerrarSesion = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContainerInfo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: ${Colors.tertiary};
  border-radius: 20px;
`;

const ProfileScreen = () => {

  const [user, setUser] = useState({})
  const userInfoAllString = localStorage.getItem("userInfoAll");

  const navigate = useNavigate()

  useEffect(() => {
    // Obtiene el objeto userInfoAll del localStorage

    if (userInfoAllString) {
      // Parsea la cadena JSON para obtener un objeto JavaScript
      const userInfoAll = JSON.parse(userInfoAllString);
      // Obtén el nombre de usuario del objeto userInfoAll y guárdalo en el estado
      setUser(userInfoAll);

    }
  }, [userInfoAllString]);

  const Location = useLocation()

  const dispatch = useDispatch()

  const logoutHandler = () => {
    dispatch(logout())
    //window.location.reload()
    navigate('/')
    window.location.reload()
  }


  return (
   <>
       <div className="layout">
        <aside className="layout__aside">
            <section className="aside__user-info">
                  <div className="user-info__general">
                    <div className="user-info__container-imagen">
                    <img src="/images/wish_boy.png" style={{ width: '100%', height: 'auto', borderRadius: '50%', background: 'white' }} ></img>
                    </div>
                    <h2 className="user-info__name">{user.first_name}</h2>
                    <h4 className="user-info__job">Perfil</h4>
                  </div>    
                <div className="user-info__buttons">
                <ButtonCommon text={'Cerrar Sesión'} typeForm={'out'} onClick={logoutHandler}></ButtonCommon>
                </div>

                <footer className="user-info__footer">
                    TuColonia.co
                </footer>
              </section>
          </aside>
          <main class="layout__content">

            <section class="content__page content__about">
               

                <div class="about__header">
                    <h2 class="about__title">Sobre <span class="title__color">Ti</span></h2>
                </div>

                <section class="about__personal-info">


                    <div class="personal-info__data">

                        <ul class="personal-info__list">

                            <li class="personal-info__option">
                            <TextParagraphNormal>📱 <strong>Celular:</strong> {user.cell_phone}</TextParagraphNormal>
                            </li>

                            <li class="personal-info__option">
                            <TextParagraphNormal>🚩 <strong>Ciudad:</strong> {user.city}</TextParagraphNormal>
                            </li>

                            <li class="personal-info__option">
                            <TextParagraphNormal>📧 <strong>Correo:</strong> {user.email}</TextParagraphNormal>
                            </li>

                            <li class="personal-info__option">
                            <TextParagraphNormal>🏠 <strong>Dirección:</strong> {user.address}</TextParagraphNormal>
                            </li>

                        </ul>

                    </div>

                </section>
              {/* 
                <section class="about__extra">
                <div class="about__header">
                    <h2 class="about__title">Tus <span class="title__color">Suscripciones</span></h2>
                </div>

                    <div class="extra__container">

                        <article class="extra__info">
                            <i class="extra__ico fa-solid fa-ticket"></i>
                            <h4 class="extra__subtitle">Suscripcion</h4>
                        </article>

                        <article class="extra__info">
                            <i class="extra__ico fa-solid fa-ticket"></i>
                            <h4 class="extra__subtitle">Suscripcion</h4>
                        </article>

                        <article class="extra__info">
                            <i class="extra__ico fa-solid fa-ticket"></i>
                            <h4 class="extra__subtitle">Suscripcion</h4>
                        </article>

                    </div>
                </section>
*/}
            </section>

        </main>
          {/*<main className="layout__content">
            
            <section className="content__page">
            
           
            <TitleCerrarSesion>
                <TitleSection>Perfil</TitleSection>
                <ButtonCommon text={'Cerrar Sesión'} typeForm={'out'} onClick={logoutHandler}></ButtonCommon>
            </TitleCerrarSesion>

            <ContainerInfo>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  <TextTitle style={{ margin: '0px' }}>{user.first_name}</TextTitle>
                  <ButtonCommon text={'Editar'} typeForm={'fill'}></ButtonCommon>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', width: '100%', flexWrap: 'wrap', marginTop: '20px', gap: '30px' }}>
                  <TextParagraphNormal>📧 <strong>Correo:</strong> {user.email}</TextParagraphNormal>
                  <TextParagraphNormal>📱 <strong>Celular:</strong> {user.cell_phone}</TextParagraphNormal>
                  <TextParagraphNormal>🚩 <strong>Ciudad:</strong> {user.city}</TextParagraphNormal>
                  <TextParagraphNormal>🐾🐾 <strong>Mascotas:</strong> {user.num_pets}</TextParagraphNormal>
                  <TextParagraphNormal>🏠 <strong>Dirección:</strong> {user.address}</TextParagraphNormal>
                  <TextParagraphNormal>🚗 <strong>Vehículos:</strong> {user.num_vehicles}</TextParagraphNormal>

                </div>

              </ContainerInfo>
             
            </section>

  </main>*/}
       </div>
   </>
   
  )

}



export default ProfileScreen