import React from "react";
import { linkCreaTuColonia } from "../constants/socialConstants";
import { wppLogo } from "../components/Images";
import '../styles/whatsappbutton.css';

export default function WhatsAppButton(){
    return(
        <a className="wppButtonContainer" href={linkCreaTuColonia} target="_blank">
            <img src={wppLogo} />
        </a>
    )
}