import React from 'react';
import SliderHome from './SliderHome';
import Collage from './Collage';
import Us from './Us';
import Steps from './Steps';
import Promotions from './Promotions';
import CardSections from './CardSections';
import Header from './Header';
import Footer from './Footer';
import Wish from './WishSection';
import WhatsAppButton from '../screens/WhatsAppButtonScreen';
import SocialButtons from '../screens/SocialButtonsScreen';
import ComoFunciona from './ComoFunciona';

function Home(){
    return(
        <>

       
                <SliderHome />
                <WhatsAppButton />
                <SocialButtons />
                <Collage />
                <Wish />
                <ComoFunciona/>
               
                {/* <Steps />                
                <CardSections /> */}
            <Footer />
        </>
    );
}

export default Home;