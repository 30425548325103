import React, { useEffect, useState } from "react"

import '../styles/customSlider.css'
import { Link } from "react-router-dom"
import ProgressBar from '../components/ProgressBarf'
import Rating from '../components/Rating'
import { formatCurrency } from "react-native-format-currency"

const PromotionCard = (props) => {
  let promotion = props.data

  return (
    <div className='prod_item'>
      <Link className='prod_img_box' to={`/product/${promotion.id_product}`}>
        <img className='prod_img' src={promotion.product_image_1} />
        <img className='prod_img_hover' src={promotion.image} />
      </Link>
      <div className='prod_text_box'>
        <Link to={`/product/${promotion.id_product}`}>
          <p className='prod_title'>{promotion.product_name}</p>
          <div className='prices'>
            {
              (promotion.discount_price) ?
                <>
                  <p className='offer_price' title='Precio de oferta'>
                    {formatCurrency({ amount: parseInt(promotion.discount_price), code: "COP" })[0]}
                  </p>
                  <p className='normal_price' title='Precio normal'>
                    {formatCurrency({ amount: parseInt(promotion.product_price), code: "COP" })[0]}
                  </p>
                </>
                :
                <p className='offer_price' title='Precio de oferta'>
                  {formatCurrency({ amount: parseInt(promotion.product_price), code: "COP" })[0]}
                </p>
            }
          </div>
          {
            (promotion.discount_price && promotion.compradoresFaltantes && promotion.compradoresMinimos) ?
              <p className='prod_desc'>
                Faltan <strong>{promotion.compradoresFaltantes}</strong> de <strong>{promotion.compradoresMinimos}</strong> unidades para que disminuya a
                <strong> {formatCurrency({ amount: parseInt(promotion.discount_price), code: "COP" })[0]}</strong>
              </p>
              : null
          }
          {
            (promotion.compradoresFaltantes && promotion.compradoresMinimos) ?
              <div className='progres_bar_container'>
                <ProgressBar percentage={1 - (promotion.compradoresFaltantes / promotion.compradoresMinimos)} />
              </div>
              : null
          }
          {
            (promotion.proveedor) ?
              <p className='prod_prov'>
                Proveedor: <strong>{promotion.proveedor}</strong>
              </p>
              : null
          }
          {
            (promotion.rating && promotion.numReviews) ?
              <div className='rating_container'>
                <Rating value={promotion.rating} text={`${promotion.numReviews} calificaciones`} color={'#f8e825'} />
              </div>
              : null
          }
        </Link>
        {/* {
          (promotion.countInStock > 0) ?
            <>
              <div className='quantity_container'>
                <div className='num_container'>
                  <button className='num_btn_l' data-id={promotion._id} onClick={restQuantityProduct}>-</button>
                  <span id={`quantity_${promotion._id}`}>0</span>
                  <button className='num_btn_r' data-id={promotion._id} data-faltantes={promotion.countInStock} onClick={sumQuantityProduct}>+</button>
                </div>
                <button className='button_set_cart' data-id={promotion._id} onClick={addToCartHandler}>Agregar al carrito</button>
              </div>
              <div className='quantity_error_container' id={`qec_${promotion._id}`} hidden>
                <small>La cantidad a comprar no puede ser igual a cero.</small>
              </div>
            </>
            :
            <div className='quantity_container'>
              Sin unidades disponibles 😒
            </div>
        } */}
      </div>
    </div>
  )
}

function CustomCarousel(props) {
  const itemsCarousel = (props.data) ? props.data : []
  const typeCarousel = (props.type) ? props.type : ''

  const [moveClass, setMoveClass] = useState('');
  const [carouselItems, setCarouselItems] = useState(itemsCarousel)

  useEffect(() => {
    document.documentElement.style.setProperty('--num', carouselItems.length);
  }, [carouselItems])

  const handleAnimationEnd = () => {
    if (moveClass === 'prev') {
      shiftNext([...carouselItems]);
    } else if (moveClass === 'next') {
      shiftPrev([...carouselItems]);
    }
    setMoveClass('')
  }

  const shiftPrev = (copy) => {
    let lastcard = copy.pop();
    copy.splice(0, 0, lastcard);
    setCarouselItems(copy);
  }

  const shiftNext = (copy) => {
    let firstcard = copy.shift();
    copy.splice(copy.length, 0, firstcard);
    setCarouselItems(copy);
  }

  return (
    <div className="carouselwrapper module-wrapper">
      <div className="carousel_button_container">
        <button onClick={() => setMoveClass('next')} className="prev">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-left-fill" viewBox="0 0 16 16">
            <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
          </svg>
        </button>
        <button onClick={() => setMoveClass('prev')} className="next">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
          </svg>
        </button>
      </div>
      <div onAnimationEnd={handleAnimationEnd} className={`${moveClass} customCarousel`}>
        {
          carouselItems.length == 0 ?
            null :
            typeCarousel == 'promotion' ?
              carouselItems.map((p) =>
                <PromotionCard key={p.id_product + p.id_promotion} data={p} />
              )
              : null
        }
      </div>
    </div>
  )
}

export default CustomCarousel