import React, { useState, useEffect } from 'react'
import { userLogo, logo_antiguo, logoutLogo } from './Images';
import '../styles/header.css';
import '../styles/home.css';
import { Link } from 'react-router-dom';
import WhatsAppButton from '../screens/WhatsAppButtonScreen';
import { logout } from '../actions/userActions';
import { useDispatch } from 'react-redux';
import SocialButtons from '../screens/SocialButtonsScreen';


function HeaderClean() {

    const [existUser, setFlagExistUser] = useState(false)
    const dispatch = useDispatch()
    let userInfo = localStorage.getItem("userInfoAll")

    const logoutHandler = () => {
        dispatch(logout())
        window.location.reload()
    }

    useEffect(() => {
        if(userInfo){
            setFlagExistUser(true)
        }
    })


    return (
        <>
            <SocialButtons />
            <WhatsAppButton />
            <div className='space_header_clean'></div>
            <header data-thq="thq-navbar" className='top_header'>
            <div className='slogan_header'>Compras Colectivas Inteligentes. Comprando Unidos, Ahorramos Unidos</div>    
                <div className="home-navbar">
                    <Link to='/'><img alt="image" src={logo_antiguo} className="home-image-logo" /></Link>
                    <div data-thq="thq-navbar-nav" data-role="Nav" className="home-desktop-menu">
                        <nav data-thq="thq-navbar-nav-links" data-role="Nav" className="home-nav">
                            {
                                (existUser) ?
                                <a type="button" onClick={logoutHandler} className="header-title img_force_display"><img src={logoutLogo} className='header_img' /></a>:
                                <Link to="/login" className="header-title img_force_display"><img src={userLogo} className='header_img' /></Link>
                            }
                            {/* <a className="header-title"><img src={wppLogo} className='header_img' /></a> */}
                            {/* <a className="header-title"><img src={instaLogo} className='header_img' /></a> */}
                        </nav>
                    </div>
                    {/* <button className="subscribe_button">Suscribirse</button> */}
                    <div data-thq="thq-burger-menu" className="home-burger-menu">
                        <button className="button home-button04">
                            <svg viewBox="0 0 1024 1024" className="home-icon14">
                                <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                            </svg>
                        </button>
                    </div>
                 
                </div>
            </header>
        </>

    )
}

export default HeaderClean
