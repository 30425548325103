import "../styles/navbar.css";
import React, { useRef, useEffect, useState } from "react";
import { Link, useParams,  useLocation,  useNavigate } from 'react-router-dom'
import { TextTitle, TextParagraphNormalSmall } from "./texts";
import { ButtonCommon } from "./buttons";
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../actions/userActions';

const Navbar = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;
  const [nameUser, setUserName] = useState('')

  //

  const userDetails = useSelector(state => state.userDetails)
  const { error, loading, user} = userDetails
  console.log(user)

  //
  
  const isAuthenticated = userInfo !== null;

  const userInfoAllString = localStorage.getItem("userInfoAll");
  
  useEffect(() => {
    // Obtiene el objeto userInfoAll del localStorage
    
    if (userInfoAllString) {
      // Parsea la cadena JSON para obtener un objeto JavaScript
      const userInfoAll = JSON.parse(userInfoAllString);
      // Obtén el nombre de usuario del objeto userInfoAll y guárdalo en el estado
      setUserName(userInfoAll.first_name);
      console.log(nameUser)
    }
  }, [userInfoAllString]); 

  const logoutHandler = () => {
    dispatch(logout())
    window.location.reload()
}


  // Utiliza useRef para obtener referencias a los elementos del DOM
  const toggleBtnRef = useRef(null);
  const toggleBtnIconRef = useRef(null);
  const dropDownMenuRef = useRef(null);

  // Manejador del evento click para el botón de alternancia
  const handleToggle = () => {
    const dropDownMenu = dropDownMenuRef.current;
    if (dropDownMenu) {
      dropDownMenu.classList.toggle("open");
      const isOpen = dropDownMenu.classList.contains("open");
      const toggleBtnIcon = toggleBtnIconRef.current;
      if (toggleBtnIcon) {
        // Actualizar la clase según el estado del menú desplegable
        toggleBtnIcon.className = isOpen
          ? "fa-solid fa-xmark"
          : "fa-solid fa-bars";
      }
    }
  };

  return (
    <header>
      <div className="navbar">
        <Link activeClass="active" smooth spy to="/">
          <div className="logo">
            <img src="/images/logo_sin_fondo.png" alt="Logo"></img>
          </div>
        </Link>


        <ul className="links">
        <li>
            <Link activeClass="active" smooth spy to="/">
              Inicio
            </Link>
          </li>
          <li>
            <Link activeClass="active" smooth spy to="/promociones/autos">
              Promociones
            </Link>
          </li>

          <li>
            <Link activeClass="active" smooth spy to="/about">
              Como Funciona
            </Link>
          </li>

          <li>
            <Link activeClass="active" smooth spy to="/email">
              Trabaja con Nosotros
            </Link>
          </li>

        </ul>
        <div className="action_btn" >
          {!isAuthenticated  ? (
            <>
              <Link activeClass="active" smooth spy to="/login">
                <ButtonCommon text={"Iniciar Sesión"} typeForm={"outline"}></ButtonCommon>
              </Link>

              <Link activeClass="active" smooth spy to="/register">
                <ButtonCommon text={"Registrate"} typeForm={"fill"}></ButtonCommon>
              </Link>
            </>
          ) : (
            <Link activeClass="active" smooth spy to="/profile">
              <div style={{ display: 'flex', justifyContent: 'center',alignItems: 'center', gap: '20px', justifyContent: 'center' }}>
                <TextParagraphNormalSmall style={{ color: "#222",margin: '0', padding: '0' }}>{nameUser}</TextParagraphNormalSmall>
                <img src="/images/wish_boy.png" style={{ width: '40px', height: '40px', borderRadius: '50%', borderColor: '#222', borderWidth: '1px', borderStyle: 'solid' }} ></img>
              </div>
            </Link>

          )}

        </div>

        <div className="toggle_btn" ref={toggleBtnRef} onClick={handleToggle}>
          <i className="fa-solid fa-bars" ref={toggleBtnIconRef}></i>
        </div>
      </div>

      <div className="dropdown_menu" ref={dropDownMenuRef}>
        <li>
          <Link activeClass="active" smooth spy to="/promociones">
            Promociones
          </Link>
        </li>

        <li>
          <Link activeClass="active" smooth spy to="about">
            Como Funciona
          </Link>
        </li>

        <li>
          <Link activeClass="active" smooth spy to="skills">
            Trabaja con Nosotros
          </Link>
        </li>


        <div className="action_btn">
          {!isAuthenticated ? (
            <>
              <Link activeClass="active" smooth spy to="/login">
                <ButtonCommon text={"Iniciar Sesión"} typeForm={"outline"}></ButtonCommon>
              </Link>
              <Link activeClass="active" smooth spy to="/register">
                <ButtonCommon text={"Registrate"} typeForm={"fill"}></ButtonCommon>
              </Link>
            </>
          ) : (
            <Link activeClass="active" smooth spy to="/profile">
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <TextParagraphNormalSmall style={{ color: "#222" }}>{nameUser}</TextParagraphNormalSmall>
                <img src="/images/wish_boy.png" style={{ width: '40px', height: '40px', borderRadius: '50%' }} ></img>
              </div>
            </Link>

          )}
        </div>
      </div>
    </header>
  );
};

export default Navbar;
