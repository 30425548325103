import React from "react";
import '../styles/socialButtonsFlaoting.css';
import { faceLogo, instaLogo, twitterLogo, ytLogo } from '../components/Images'
import { linkInstagram, linkFB, linkTwitter, linkYT } from '../constants/socialConstants'

function SocialButtons() {
    return (
        <>
            <div className='social_floating_container'>
                {/* <a href={linkTwitter} target='_blank' className="twitter_container">
                    <span>twitter</span>
                    <img src={twitterLogo} />
                </a> */}
                <a href={linkInstagram} target='_blank' className="insta_container">
                    <span>Instagram</span>
                    <img src={instaLogo} />
                </a>
                <a href={linkFB} target='_blank' className="fb_container">
                    <span>Facebook</span>
                    <img src={faceLogo} />
                </a>
                <a href={linkYT} target='_blank' className="yt_container">
                    <span>YouTube</span>
                    <img src={ytLogo} />
                </a>
            </div>
        </>
    )
}

export default SocialButtons