import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Us from './components/Us';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import AllProductsScreen from './screens/AllProductsScreen.js';
import CategoryScreen from './screens/CategoryScreen';
import ProductScreen from './screens/ProductScreen';
import ShippingScreen from './screens/ShippingScreen';
import CartScreen from './screens/CartScreen';
import PaymentScreen from './screens/PaymentScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen';
import OrderScreen from './screens/OrderScreen';
import FacilitatorScreen from './screens/FacilitatorScreen';
import ProductResult from './screens/ProductResultScreen';
import PasswordResetEmail from './screens/PasswordResetEmailScreen';
import PasswordReset from './screens/PasswordResetScreen';
import CustomCarousel from './components/CustomCarousel';
import SupplierScreen from './screens/SupplierScreen';
import MakeWishScreen from './screens/MakeWishScreen';
import GasolinaScreen from './screens/GasolinaScreen';
import ProfileScreen from './screens/ProfileScreen';
import PinturaScreen from './screens/PinturaScreen';
import { GlobalStyle } from './styles/global.js';
import Header from './components/Header';
import Email from './screens/Email';
import PromocionesScreen from './screens/PromocionesScreen';


function App() {
  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <Header /> {/* Agrega la barra de navegación */}
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/about' element={<Us />} />
          <Route exact path='/login' element={<LoginScreen />} />
          <Route path="/register" element={<RegisterScreen />} />
          <Route path="/profile" element={<ProfileScreen />} />
          <Route path='/password_reset_email/' element={<PasswordResetEmail />}/>
          <Route path='/email' element={<Email />}/>
          <Route path='/promociones' element={<PromocionesScreen />}/>
          <Route path="/promociones/:category" element={<PromocionesScreen />} />
          {/* Agrega más rutas según tus necesidades */}
        </Routes>
      </BrowserRouter>
    </>
    
  );
}

export default App;

/*
<>
    <GlobalStyle /> {}
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/login' element={<LoginScreen />} />
        <Route path="/register" element={<RegisterScreen />} />
        <Route path="/profile" element={<ProfileScreen />} />

        <Route path="/category/:id" element={<CategoryScreen />} />
        <Route path="/product/" element={<ProductScreen />} />
        <Route path="/product/:id" element={<ProductScreen />} />

        <Route path="/cart" element={<CartScreen />} />
        <Route path="/cart/:id" element={<CartScreen />} />
        <Route path="/shipping" element={<ShippingScreen />} />
        <Route path="login/shipping" element={<ShippingScreen />} />
        <Route path="/payment" element={<PaymentScreen />} />
        <Route path="/placeorder" element={<PlaceOrderScreen />} />
        <Route path="/order/:id" element={<OrderScreen />} />

        {}
        <Route path="/products/" element={<AllProductsScreen />} />
        <Route path="/products/search/:name" element={<ProductResult />}/>
        <Route path="/products/:id" element={<AllProductsScreen />} />
        <Route path="/facilitator/" element={<FacilitatorScreen />} />
        <Route path="/supplier/" element={<SupplierScreen />} />
        <Route path='/password_reset_email/' element={<PasswordResetEmail />}/>
        <Route path='/password_reset' element={<PasswordReset />}/>
        <Route path='/make_wish' element={<MakeWishScreen />}/>
        <Route path='/gasolina' element={<GasolinaScreen />}/>
        <Route path='/pintahogar' element={<PinturaScreen />}/>

        <Route path='/custom_carousel' element={<CustomCarousel />}/>
      </Routes>
    </BrowserRouter>
    </>


    <a href="https://storyset.com/mobile">Mobile illustrations by Storyset</a>

*/

