export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const USER_LOGOUT = 'USER_LOGOUT'

//GEt all information
export const USER_LOGIN_REQUEST_INFO = 'USER_LOGIN_REQUEST_INFO'
export const USER_LOGIN_SUCCESS_INFO = 'USER_LOGIN_SUCCESS_INFO'
export const USER_LOGIN_FAIL_INFO = 'USER_LOGIN_FAIL_INFO'

//Logout
export const USER_LOGOUT_INFO = 'USER_LOGOUT_INFO'

//Register
export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_REGISTER_REQUEST_INFO = 'USER_REGISTER_REQUEST_INFO'
export const USER_REGISTER_SUCCESS_INFO = 'USER_REGISTER_SUCCESS_INFO'
export const USER_REGISTER_FAIL_INFO = 'USER_REGISTER_FAIL_INFO'

//Show details
export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'

export const USER_DETAILS_REQUEST_INFO = 'USER_DETAILS_REQUEST_INFO'
export const USER_DETAILS_SUCCESS_INFO = 'USER_DETAILS_SUCCESS_INFO'
export const USER_DETAILS_FAIL_INFO = 'USER_DETAILS_FAIL_INFO'

//Update
export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const USER_UPDATE_REQUEST_INFO = 'USER_UPDATE_REQUEST_INFO'
export const USER_UPDATE_SUCCESS_INFO = 'USER_UPDATE_SUCCESS_INFO'
export const USER_UPDATE_FAIL_INFO = 'USER_UPDATE_FAIL_INFO'
export const USER_UPDATE_RESET_INFO = 'USER_UPDATE_RESET_INFO'

//Password Reset https://www.youtube.com/watch?v=ZUjlLN510Fo
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL'
export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS'
export const PASSWORD_RESET_CONFIRM_FAIL = 'PASSWORD_RESET_CONFIRM_FAIL'

//ADMIn LIST USERS
export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'

export const USER_UPDATEADMIN_REQUEST = 'USER_UPDATEADMIN_REQUEST'
export const USER_UPDATEADMIN_SUCCESS = 'USER_UPDATEADMIN_SUCCESS'
export const USER_UPDATEADMIN_FAIL = 'USER_UPDATEADMIN_FAIL'
export const USER_UPDATEADMIN_RESET = 'USER_UPDATEADMIN_RESET'