import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools} from 'redux-devtools-extension'
import { 
          productListReducer,
          productDetailsReducer,
          productListCategoryReducer,
          productDeleteReducer,
          productCreateReducer,
          productUpdateReducer,
          productReviewCreateReducer,
          productTopRatedReducer,
          promotionsListReducer,
       } from './reducers/productReducers'
import { categoryListReducer,categoryDetailsReducer} from './reducers/categoryReducers'
import { cartReducer} from './reducers/cartReducers'
import { userLoginReducer, userAllInfoLoginReducer, userRegisterReducer, userAllRegisterReducer, userDetailsReducer, userDetailsReducerAll, userUpdateProfileReducer, userUpdateProfileAllReducer, userRememberPasswordReducer, userListReducer, userDeleteReducer, userUpdateReducer } from './reducers/userReducers'
import { orderCreateReducer, orderDetailsReducer, orderPayReducer, orderListMyReducer,orderListReducer,  orderDeliverReducer } from './reducers/orderReducers'

const reducer = combineReducers({
    productList: productListReducer,
    productDetails: productDetailsReducer, 
    productCategoryList: productListCategoryReducer,
    productDelete:productDeleteReducer,
    productCreate: productCreateReducer,
    productUpdate: productUpdateReducer,
    productReviewCreate: productReviewCreateReducer,
    productTopRated: productTopRatedReducer,
    categoryList: categoryListReducer,
    categoryDetails: categoryDetailsReducer,
    cart:cartReducer,
    userLogin:userLoginReducer,
    userAllLogin:userAllInfoLoginReducer,
    userRegister:userRegisterReducer,
    userAllRegister:userAllRegisterReducer,
    userDetails:userDetailsReducer,
    userDetailsAll:userDetailsReducerAll,
    userUpdateProfile:userUpdateProfileReducer,
    userUpdateProfileAll:userUpdateProfileAllReducer,
    userRememberPassword:userRememberPasswordReducer,
    userList:userListReducer,
    userDelete:userDeleteReducer,
    userUpdate:userUpdateReducer,
    orderCreate:orderCreateReducer,
    orderDetails: orderDetailsReducer,
    orderPay:orderPayReducer,
    orderListMy:orderListMyReducer,
    orderList: orderListReducer,
    orderDeliver: orderDeliverReducer,
    promotionsList: promotionsListReducer
})

const cartItemsFromStorage = localStorage.getItem('cartItems') ?
     JSON.parse(localStorage.getItem('cartItems')):[]

const userInfoFromStorage = localStorage.getItem('userInfo') ?
     JSON.parse(localStorage.getItem('userInfo')):null

const userInfoAllFromStorage = localStorage.getItem('userInfoAll') ?
     JSON.parse(localStorage.getItem('userInfoAll')):null  

const shippingAddressFromStorage = localStorage.getItem('shippingAddress') ?
     JSON.parse(localStorage.getItem('shippingAddress')):{}

const paymentMethodFromStorage = localStorage.getItem('paymentMethod') ?
     JSON.parse(localStorage.getItem('paymentMethod')):{}

const middleware = [thunk]

const initialState = {
    cart:{
          cartItems: cartItemsFromStorage,
          shippingAddress: shippingAddressFromStorage,
          paymentMethod: paymentMethodFromStorage,
         },
    userLogin: {userInfo: userInfoFromStorage},
    userAllLogin: {userInfoAll: userInfoAllFromStorage},

}
const store = createStore(reducer,initialState,
     composeWithDevTools(applyMiddleware(...middleware)))
     
export default store