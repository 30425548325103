import React, { useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../styles/facilitator.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Form, Row, Col, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { refresh } from '../components/Images';

let pageForm = 1

const formatNumber = (number) => {
    return (number < 10) ? '0' + number : number
}

const getCurrentDate = () => {
    const current = new Date();
    const date = `${current.getFullYear()}-${formatNumber(current.getMonth() + 1)}-${formatNumber(current.getDate())}`
    const time = `${current.getHours()}:${formatNumber(current.getMinutes())}:${formatNumber(current.getSeconds())}`

    return `${date}T${time}`
}

const generateString = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!*%&$';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
}

function PinturaScreen() {
    const [data, setData] = useState({
        "name_user": "",
        "cellphone": "",
        "email": "",
        "city": "",
        "neighborhood": "",
        "adress": "",
    })
    const inputRequireds = [
        "name_user",
        "cellphone",
        "email",
        "city",
        "neighborhood",
        "adress",
    ]
    const [prevDisabled, setPrevDisabled] = useState(true)
    const [showModal, setShowModal] = useState(false)

    const handleClose = () => {
        setShowModal(false)
        window.location.href = '/'
    }

    const handleChange = (e) => {
        let inputName = e.target.name
        let inputValue = e.target.value

        setData({
            ...data, [inputName]: inputValue
        })

        if (document.getElementById(`error_${inputName}`)) {
            document.getElementById(`error_${inputName}`).style.display = "none"
        }
        document.getElementsByName(inputName)[0].classList.remove("error")
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) { return }

        let body_form = document.getElementById("body_form")
        let spinner_form = document.getElementById("spinner_form")
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
        }
        let dF = data

        body_form.style.opacity = ".5"
        spinner_form.style.display = "flex"

        const dataForm = {
            "name_user": dF.name_user,
            "cellphone": dF.cellphone,
            "email": dF.email,
            "city": dF.city,
            "neighborhood": dF.neighborhood,   
            "adress": dF.adress,   
            "created_at": getCurrentDate()
        }

        axios.post('https://thor-fast-api.herokuapp.com/paints/register', dataForm, config).then(res => {
            if (res.status == 200) {
                body_form.style.opacity = "1"
                spinner_form.style.display = "none"
                setShowModal(true)
                return true
            }
        }).catch(error => {
            console.log("Error registrando pintura: ", error)
            body_form.style.opacity = "1"
            spinner_form.style.display = "none"
            setShowModal(true)
        });
        body_form.style.opacity = ".5"
        spinner_form.style.display = "flex"

    }

    const validateForm = () => {
        let response = true
        let currentDivStep = document.getElementById(`step_${pageForm}`)

        let inputsCurrentStep = currentDivStep.getElementsByTagName("input")
        if (inputsCurrentStep.length > 0) {
            inputRequireds.forEach((inputName) => {
                if (data[inputName] == "" && inputsCurrentStep[inputName]) {
                    response = false
                    document.getElementById(`error_${inputName}`).style.display = "block"
                    document.getElementsByName(inputName)[0].classList.add("error")
                }
            })
        }
        return response
    }

    const nextPageForm = (e) => {
        if (!validateForm()) { return }

        let stepFormElements = document.getElementsByClassName("stepForm");
        if (pageForm <= stepFormElements.length) {
            for (let i = 0; i < stepFormElements.length; i++) {
                stepFormElements[i].setAttribute("hidden", true)
            }

            document.getElementById(`breadcrumb_${pageForm}`).classList.remove("active")
            pageForm += 1
            pageForm = (pageForm >= stepFormElements.length) ? stepFormElements.length : pageForm
            if (pageForm == stepFormElements.length) {
                e.target.setAttribute("hidden", true)
                document.getElementById("subm_btn").removeAttribute("hidden")
            } else {
                e.target.removeAttribute("hidden")
            }

            if (pageForm > 1) {
                setPrevDisabled(false)
            }
            document.getElementById(`step_${pageForm}`).removeAttribute("hidden")
            document.getElementById(`breadcrumb_${pageForm}`).classList.add("active")
        }
    }

    const prevPageForm = (e) => {
        if (pageForm > 0) {
            let stepFormElements = document.getElementsByClassName("stepForm");
            for (let i = 0; i < stepFormElements.length; i++) {
                stepFormElements[i].setAttribute("hidden", true)
            }

            document.getElementById(`breadcrumb_${pageForm}`).classList.remove("active")
            pageForm -= 1
            pageForm = (pageForm == 0) ? 1 : pageForm


            if (pageForm == 1) {
                setPrevDisabled(true)
            } else {
                setPrevDisabled(false)
            }

            if (pageForm < stepFormElements.length) {
                document.getElementById("next_btn").removeAttribute("hidden")
                document.getElementById("subm_btn").setAttribute("hidden", true)
            }

            document.getElementById(`step_${pageForm}`).removeAttribute("hidden")
            document.getElementById(`breadcrumb_${pageForm}`).classList.add("active")
        }
    }

    return (
        <>
            <Header />
            <div className='prod_portada'>
                <h3 className='login_title'>Afiliate a la promoción de Pinta tu Hogar</h3>
            </div>
            <Modal show={showModal} onHide={handleClose} className='modal_facilitator'>
                <Modal.Body>
                    Hola <strong>{data.supplier_name}!</strong><br />
                    Nos hace muy felices que quieras ser parte de esta Promoción.<br />
                    En breve nos comunicaremos contigo.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
            <body className='body_facilitator'>
            <Form className='facilitator_container' onSubmit={handleSubmit}>
                <div class="spinner" id="spinner_form">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <p>Enviando...</p>
                </div>
                <div id='body_form'>
                    <div className='breadcrumbs'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-circle-fill active" id='breadcrumb_1' viewBox="0 0 16 16">
                            <circle cx="8" cy="8" r="8" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-circle-fill" id='breadcrumb_2' viewBox="0 0 16 16">
                            <circle cx="8" cy="8" r="8" />
                        </svg>
                    </div>
                    <div className='stepForm' id='step_1' >
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="🧑 Nombre Completo *">
                                    <Form.Control type="text" placeholder="🧑 Nombre Completo *" name='name_user' value={data.name_user} onChange={handleChange} />
                                    <small id='error_name_user' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="📱 Celular *">
                                    <Form.Control type="number" placeholder="📱Celular *" name='cellphone' value={data.cellphone} onChange={handleChange} />
                                    <small id='error_cellphone' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>                            
                        </Row>                       

                        <Row className="g-2">
                             <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="📧 Correo Electronico*">
                                    <Form.Control type="email" placeholder="📧 Correo Electronico*" name='email' value={data.email} onChange={handleChange} />
                                    <small id='error_email' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="🌆 Ciudad *">
                                    <Form.Control type="text" placeholder="🌆 Ciudad *" name='city' value={data.city} onChange={handleChange} />
                                    <small id='error_city' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>                          
                        </Row>
                        
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="🏙️ Barrio *">
                                    <Form.Control type="text" placeholder="🏙️ Barrio *" name='neighborhood' value={data.neighborhood} onChange={handleChange} />
                                    <small id='error_neighborhood' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                             <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="🏢 Dirección *">
                                    <Form.Control type="text" placeholder="🏢 Dirección *" name='adress' value={data.adress} onChange={handleChange} />
                                    <small id='error_adress' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>

                        </Row>
                    </div>
                    <Row className='action_btn_container'>
                        <Button className='prev_btn' id='prev_btn' onClick={prevPageForm} disabled={prevDisabled}>Atrás</Button>
                        <Button className='next_btn' id='subm_btn' type="submit">Enviar</Button>
                    </Row>
                </div>
            </Form>
            </body>
            <Footer />
        </>
    )
}

export default PinturaScreen
