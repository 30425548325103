import React, { useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../styles/facilitator.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Form, Row, Col, Button, Modal, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import { refresh } from '../components/Images';

let pageForm = 1

const formatNumber = (number) => {
    return (number < 10) ? '0' + number : number
}

const getCurrentDate = () => {
    const current = new Date();
    const date = `${current.getFullYear()}-${formatNumber(current.getMonth() + 1)}-${formatNumber(current.getDate())}`
    const time = `${current.getHours()}:${formatNumber(current.getMinutes())}:${formatNumber(current.getSeconds())}`

    return `${date}T${time}`
}

const formatDate = (dateToFormat) => {
    const dateSend = new Date(dateToFormat);
    const date = `${dateSend.getFullYear()}-${formatNumber(dateSend.getMonth() + 1)}-${formatNumber(dateSend.getDate())}`
    const time = `${dateSend.getHours()}:${formatNumber(dateSend.getMinutes())}:${formatNumber(dateSend.getSeconds())}`

    return `${date}T00:00:00.000Z`
}

function generateString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!*%&$';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
}

function GasolinaScreen() {
    const [data, setData] = useState({
        "license_plate_car": "",
        "brand_car": "",
        "name_owner": "",
        "cellphone_owner": "",
        "email_owner": ""
    })
    const inputRequireds = [
        "license_plate_car",
        "brand_car",
        "name_owner",
        "cellphone_owner",
        "email_owner"
    ]
    const [prevDisabled, setPrevDisabled] = useState(true)
    const [showModal, setShowModal] = useState(false)

    const handleClose = () => {
        setShowModal(false)
        window.location.href = '/'
    }

    const handleChange = (e) => {
        let inputName = e.target.name
        let inputValue = e.target.value

        setData({
            ...data, [inputName]: inputValue
        })

        if (document.getElementById(`error_${inputName}`)) {
            document.getElementById(`error_${inputName}`).style.display = "none"
        }
        document.getElementsByName(inputName)[0].classList.remove("error")
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) { return }

        let body_form = document.getElementById("body_form")
        let spinner_form = document.getElementById("spinner_form")
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
        }
        let dF = data

        body_form.style.opacity = ".5"
        spinner_form.style.display = "flex"

        const dataForm = {
            "license_plate_car": dF.license_plate_car.toString(),
            "brand_car": dF.brand_car.toString(),
            "name_owner": dF.name_owner.toString(),
            "cellphone_owner": dF.cellphone_owner.toString(),
            "email_owner": dF.email_owner.toString(),       
            "created_at": getCurrentDate()
        }
        console.log("dataForm: ", dataForm)
        axios.post('https://thor-fast-api.herokuapp.com/cars/register', dataForm, config).then(res => {
            if (res.status == 200) {
                body_form.style.opacity = "1"
                spinner_form.style.display = "none"
                setShowModal(true)
                return true
            }
        }).catch(error => {
            // Define los datos que deseas enviar en el cuerpo de la solicitud
            const datos = {
                error: error,
                log: JSON.stringify(dataForm)
            };
            
            // Realiza la solicitud POST con Axios
            axios.post('https://thor-fast-api.herokuapp.com/error/register', datos)
                .then(response => {
                // Maneja la respuesta si es necesario
                 console.log('Respuesta del servidor:', response.data);
                })
                .catch(error => {
                // Maneja los errores si ocurre alguno
                 console.error('Error al enviar la solicitud:', error);
                });

            body_form.style.opacity = "1"
            spinner_form.style.display = "none"
            setShowModal(true)
        });
        body_form.style.opacity = ".5"
        spinner_form.style.display = "flex"
    }

    const validateForm = () => {
        let response = true
        let currentDivStep = document.getElementById(`step_${pageForm}`)

        let inputsCurrentStep = currentDivStep.getElementsByTagName("input")
        if (inputsCurrentStep.length > 0) {
            inputRequireds.forEach((inputName) => {
                if (data[inputName] == "" && inputsCurrentStep[inputName]) {
                    response = false
                    document.getElementById(`error_${inputName}`).style.display = "block"
                    document.getElementsByName(inputName)[0].classList.add("error")
                }
            })
        }

        return response
    }

    const nextPageForm = (e) => {
        if (!validateForm()) { return }

        let stepFormElements = document.getElementsByClassName("stepForm");
        if (pageForm <= stepFormElements.length) {
            for (let i = 0; i < stepFormElements.length; i++) {
                stepFormElements[i].setAttribute("hidden", true)
            }

            document.getElementById(`breadcrumb_${pageForm}`).classList.remove("active")
            pageForm += 1
            pageForm = (pageForm >= stepFormElements.length) ? stepFormElements.length : pageForm
            if (pageForm == stepFormElements.length) {
                e.target.setAttribute("hidden", true)
                document.getElementById("subm_btn").removeAttribute("hidden")
            } else {
                e.target.removeAttribute("hidden")
            }

            if (pageForm > 1) {
                setPrevDisabled(false)
            }
            document.getElementById(`step_${pageForm}`).removeAttribute("hidden")
            document.getElementById(`breadcrumb_${pageForm}`).classList.add("active")
        }
    }

    const prevPageForm = (e) => {
        if (pageForm > 0) {
            let stepFormElements = document.getElementsByClassName("stepForm");
            for (let i = 0; i < stepFormElements.length; i++) {
                stepFormElements[i].setAttribute("hidden", true)
            }

            document.getElementById(`breadcrumb_${pageForm}`).classList.remove("active")
            pageForm -= 1
            pageForm = (pageForm == 0) ? 1 : pageForm

            if (pageForm == 1) {
                setPrevDisabled(true)
            } else {
                setPrevDisabled(false)
            }

            if (pageForm < stepFormElements.length) {
                document.getElementById("next_btn").removeAttribute("hidden")
                document.getElementById("subm_btn").setAttribute("hidden", true)
            }

            document.getElementById(`step_${pageForm}`).removeAttribute("hidden")
            document.getElementById(`breadcrumb_${pageForm}`).classList.add("active")
        }
    }

    return (
        <>
            <Header />
            <div className='prod_portada'>
                <h3 className='login_title'>Afiliate a la promoción de Gasolina</h3>
            </div>
            <Modal show={showModal} onHide={handleClose} className='modal_facilitator'>
                <Modal.Body>
                    Hola <strong>{data.supplier_name}!</strong><br />
                    Nos hace muy felices que quieras ser parte de esta Colonia.<br />
                    En breve nos comunicaremos contigo.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
            <body className='body_facilitator'>
            <Form className='facilitator_container' onSubmit={handleSubmit}>
                <div class="spinner" id="spinner_form">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <p>Enviando...</p>
                </div>
                <div id='body_form'>
                    <div className='breadcrumbs'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-circle-fill active" id='breadcrumb_1' viewBox="0 0 16 16">
                            <circle cx="8" cy="8" r="8" />
                        </svg>
                    </div>
                    <div className='stepForm' id='step_1' >
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Placa Vehiculo *">
                                    <Form.Control type="text" placeholder="Placa Vehiculo *" name='license_plate_car' value={data.license_plate_car} onChange={handleChange} autoComplete="off"/>
                                    <small id='error_license_plate_car' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Marca del vehiculo *">
                                    <Form.Control type="text" placeholder="Marca del vehiculo *" name='brand_car' value={data.brand_car} onChange={handleChange} autoComplete="off" />
                                    <small id='error_brand_car' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Nombre del propietario *">
                                    <Form.Control type="text" placeholder="Nombre completo del propietario *" name='name_owner' value={data.name_owner} onChange={handleChange} autoComplete="off"/>
                                    <small id='error_name_owner' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="g-2">                        
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Celular *">
                                    <Form.Control type="number" placeholder="Celular *" name='cellphone_owner' value={data.cellphone_owner} onChange={handleChange} autoComplete="off"/>
                                    <small id='error_name_owner' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel controlId="floatingInputGrid" label="Correo Electronico*">
                                    <Form.Control type="email" placeholder="Correo Electronico*" name='email_owner' value={data.email_owner} onChange={handleChange} autoComplete="off"/>
                                    <small id='error_email_owner' className='form_error_input'>Por favor rellene este campo</small>
                                </FloatingLabel>
                            </Col>
                        </Row>                        
                    </div>
                    <Row className='action_btn_container'>
                        <Button className='prev_btn' id='prev_btn' onClick={prevPageForm} disabled={prevDisabled}>Atrás</Button>
                        <Button className='next_btn' id='subm_btn' type="submit">Enviar</Button>
                    </Row>
                </div>
            </Form>
            </body>
            <Footer />
        </>
    )
}

export default GasolinaScreen
