import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import "../styles/bar.css";

function ProgressBarf({ percentage }) {
  const progress = percentage / 200; // Calcula el progreso entre 0 y 1
  return (
    
    <ProgressBar now={progress * 100} label={`${progress * 100}%`} className='barStyle' />
  );
}

export default ProgressBarf
