import React from "react";
import nosotros from '../images/nosotros.png';
import '../styles/us.css';
import Footer from './Footer';
import styled from 'styled-components';
import "../styles/navbar.css";

const ContainerMain = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TextTitle = styled.h2`
    font-size: 1.3rem;
    font-weight: 600;
    margin: 0 auto;
`

const TitleSection = styled.h2`
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    
    @media (max-width: 868px) {
    font-size: 1.5rem;
  }
`


const TextParagraphNormal = styled.p`
    color: #666666;
    font-size: 18px;
    `

const ButtonCommonFill = styled.button`
    background-color: #87B338;
    color: #FEFCFB;
    padding: 8px 10px;
    min-width: 150px;
    /* sombra del boton*/
    box-shadow: 5px 6px 0px 0px #000000;
    font-weight: 500;
    border-width: 1px;
    border-radius: 15px;
    transition: transform 0.3s; /* Agrega una transición a la propiedad transform */

    //hover
    &:hover{
        background-color: #FEFCFB;
        color: #87B338;
        border: #87B338 solid 1px;
        transform: scale(0.95); /* Reduce el tamaño del botón al 95% en hover */
    }
    `

const ContainerContentText = styled.div`
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    padding: 20px;
    @media (max-width: 768px) {
      width: 100%;
    }
    `;

const ContainerSection = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin-top: 50px;
    @media (max-width: 768px) {
      flex-direction: column !important;
    }
  `;


const ContainerImage = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      width: auto;
    }
  `;

function Us(){
    // return(
    //     <div className="nosotros_container" id="como_funciona">
    //         <div className="texto_container">
    //             <h1>Así hacemos compras inteligentes</h1>
    //             <p>Primer plataforma de compras colectivas inteligentes de latinoamerica</p>
    //             <iframe src="https://www.youtube.com/embed/uMqmdF-FmGw" title="Tu Colonia Compras Colectivas Inteligentes. Cómo funciona?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    //         </div>
    //         <div className="imagen_container">
    //             <img src={nosotros} />
    //         </div>
    //     </div>
    // );
    return (
        <>
          <div className="container nosotros_container" id="como_funciona">
            <div className="row mb-4">
            <div className="col-md-12 text-center mb-4">
                <h1  className="style_title">Así hacemos compras inteligentes</h1>
                <p>Primer plataforma de compras colectivas inteligentes de latinoamerica</p>  
            </div>
            <div className="col-md-6">
                <iframe className="video_container" src="https://www.youtube.com/embed/uMqmdF-FmGw?autoplay=1&muted=1" title="Tu Colonia Compras Colectivas Inteligentes. Cómo funciona?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-purpose; web-share" allowfullscreen></iframe>
            </div>

             <div className="col-md-4 align-items-center imagen_container order-md-2">
                <img className="img_style" src={nosotros} />
            </div>   
            </div>         
        </div>
        <ContainerMain>
           <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',justifyContent: 'start',width:'60%', height:'100%'}}>
           
                           
            {/* Seccion 02*/}
            <ContainerSection style={{ flexDirection: 'row-reverse' }}>
                    <ContainerContentText>
                        <TitleSection style={{ marginBottom: '50px' }}>Haz tu pedido! 🛍️</TitleSection>
                        <TextTitle style={{ color: '#635994' }}>¡Regístrate y empieza a ahorrar! </TextTitle>
                        <TextParagraphNormal>Suscríbete a las promociones para desbloquear increíbles descuentos en tus productos favoritos.</TextParagraphNormal>
                    </ContainerContentText>
                    <ContainerImage>
                        <img style={{ width: '80%', height: 'auto' }} src='/images/comofunciona/hacerpedido.svg' />
                    </ContainerImage>
                </ContainerSection>

                 {/* Seccion 03*/}
                 <ContainerSection >
                    <ContainerContentText>
                        <TitleSection style={{ marginBottom: '50px' }}>Se hace magia! ✨</TitleSection>

                        <TextParagraphNormal>Tu Colonia al recibir tu suscripción en un producto genera un código promocional exclusivo para ti para que obtengas un descuento en tu compra.
                            Te ponemos en contacto con el proveedor y ellos te enviarán tu producto.</TextParagraphNormal>
                    </ContainerContentText>
                    <ContainerImage>
                        <img style={{ width: '90%', height: 'auto' }} src='/images/comofunciona/proveedor.svg' />
                    </ContainerImage>
                </ContainerSection>

                
                 {/* Seccion 04*/}
                 <ContainerSection style={{ flexDirection: 'row-reverse' }}>
                    <ContainerContentText>
                        <TitleSection style={{ marginBottom: '50px' }}>Disfruta! 😎</TitleSection>
                        
                        <TextParagraphNormal>Disfruta de tus productos favoritos con Tu Colonia.</TextParagraphNormal>
                    </ContainerContentText>
                    <ContainerImage>
                        <img style={{ width: '80%', height: 'auto' }} src='/images/comofunciona/personasCompras.svg' />
                    </ContainerImage>
                </ContainerSection>

                <TitleSection style={{ marginTop: '100px', marginBottom:'50px' }}>Ingresa y entérate de las promociones que tenemos para ti</TitleSection>

                <ButtonCommonFill>Promociones</ButtonCommonFill>
                

                <TextParagraphNormal style={{ marginTop: '200px', textAlign: 'center' }}><strong>Gracias por unirte.</strong> Si tienes alguna pregunta o necesitas ayuda, no dudes en ponerte en contacto con nuestro equipo de atención al cliente. ¡Estamos aquí para ayudarte! Tucolonia.co</TextParagraphNormal>

            <TextParagraphNormal style={{ marginTop: '50px', marginBottom:'50px', textAlign: 'center' }}>¡Que disfrutes al máximo de <a href="https://www.tucolonia.co"><strong>Tu colonia!</strong></a>.</TextParagraphNormal>
            <Footer />
           </div>
                       
      
        </ContainerMain>
        
        
    </>
);
}

export default Us;