import React from 'react'
import { styled } from '@mui/material/styles';

import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';


const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('sm')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &.Mui-focusVisible': {
      zIndex: 1,
      '& .MuiImageBackdrop-root': {
        opacity: 0.5,
      },
      '& .MuiImageMarked-root': {
        opacity: 0,
      },
      '& .MuiTypography-root': {
        border: '7px solid currentColor',
      },
    },
  }));

  const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 60%',
  });
  
  const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  }));
  
  const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.65,
    transition: theme.transitions.create('opacity'),
  }));
  
  const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 30,
    backgroundColor: "#78C2AD",
    position: 'absolute',
    bottom: -2,
    opacity: 0.9,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  }));


function Category({ cate })  {
  return (        
        <ImageButton   name= {cate.categoria}  focusRipple key={cate._id} style={{width: "16%"}}>            
                <ImageSrc style={{ backgroundImage: `url(${cate.image})` }} />
                <ImageBackdrop className="MuiImageBackdrop-root" />
                <Image>
                <Typography
                    component="span"
                    variant="h5"
                   /*  color="inherit" */
                    color="#78C2AD"
                    sx={{
                    position: 'relative',
                    p: 4,
                    pt: 2,
                    pb: (theme) => `calc(${theme.spacing(1)} + 17px)`,
                    }}
                >
                    {cate.categoria}
                    <ImageMarked className="MuiImageMarked-root" />
                </Typography>          
                </Image>        
            </ImageButton>     
  
        

  )
}

export default Category
