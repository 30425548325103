import React, { useState } from 'react';
import { ButtonCommon } from '../components/buttons';
import FormContainer from '../components/FormContainer'
import { Form, Button, Row, Col } from 'react-bootstrap'
import '../styles/login.css';
import { TextParagraphNormal, TextTitle, TitleSection } from '../components/texts';
import '../styles/whishPopUp.css';
const styles = {
  popup: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  popupContent: {
    background: 'white',
    padding: 20,
    borderRadius: 5,
    marginTop: 100,
  },
};

const PopupForm = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenPopup = () => {
    setIsOpen(true);
  };

  const handleClosePopup = () => {
    setIsOpen(false);
  };

  return (
    <>
      <ButtonCommon text="Pide un Deseo" typeForm="outline" onClick={handleOpenPopup} />
      {isOpen && (
        <div className="popup" style={styles.popup}>
          <div className="popup-content" style={styles.popupContent}>
          <button className="close-button" onClick={handleClosePopup}>✕</button>
            <FormContainer>
            <Form className='login_form'>
              <Row>
              <TitleSection style={{ marginBottom: '10px' }}>Desea aquí!</TitleSection>
              </Row>
              <Form.Group controlId='email'>
                <Form.Label>Nombre: </Form.Label>
                <Form.Control className='login_textfield' type='text' placeholder='Tu nombre completo' ></Form.Control>
              </Form.Group>
              <Form.Group controlId='email'>
                <Form.Label>Usuario: </Form.Label>
                <Form.Control className='login_textfield' type='text' placeholder='Tu usuario' ></Form.Control>
              </Form.Group>
              <Form.Group className='mb-3' controlId='password'>
                <Form.Label>Correo: </Form.Label>
                <Form.Control className='login_textfield' type='email' placeholder='@gmail.com' ></Form.Control>
              </Form.Group>
              <Form.Group className='mb-3' controlId='password'>
                <Form.Label>Tu deseo: </Form.Label>
                <Form.Control className='login_textfield' type='text' placeholder='Pide tu deseo' ></Form.Control>
              </Form.Group>
              <ButtonCommon  text={'Pide tu deseo'} typeForm={'fill'}  type='submit' variant='primary'></ButtonCommon>             
            </Form>
          </FormContainer>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupForm;