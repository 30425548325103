import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { logo, userLogo, shoppingCart, logoutLogo, logo_antiguo } from './Images';
import { logout } from '../actions/userActions';
import { Link } from 'react-router-dom';
import { Form, FormControl, InputGroup } from "react-bootstrap";
import WhatsAppButton from '../screens/WhatsAppButtonScreen';
import SocialButtons from '../screens/SocialButtonsScreen';
import Navbar from './NavBar';

let listVisible = false;

function changeIcon() {
  let nav_arrow = document.getElementById("nav_arrow");
  let nav_list_container = document.getElementById("nav_list_container");

  if (!listVisible) {
    nav_list_container.removeAttribute("hidden");
    listVisible = true
    nav_arrow.style.cssText = 'transform: rotate(180deg);';
  } else {
    nav_list_container.setAttribute("hidden", true);
    listVisible = false
    nav_arrow.style.cssText = 'transform: rotate(0deg);';
  }

}

function Header(props_meta) {
  const [existUser, setFlagExistUser] = useState(false)
  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin
  const [navVisible, setNavVisible] = useState(true)

  const userAllLogin = useSelector(state => state.userAllLogin)
  const { userInfoAll } = userAllLogin

  const saved = localStorage.getItem("userInfoAll");
  const initialValue = JSON.parse(saved);

  const dispatch = useDispatch()

  const logoutHandler = () => {
    dispatch(logout())
    window.location.reload()
  }

  useEffect(() => {
    if (saved) {
      setFlagExistUser(true)
    }
  })

  // BUSCADOR
  const [nameProduct, setNameProduct] = useState('')

  const handleSubmit = (event) => {
    event.preventDefault();
    if (nameProduct != "") {
      window.location.href = `/products/search/${nameProduct}`
    }
  }

  //NAVBAR MOBILE
  const toggleNavMobile = () => {
    setNavVisible(!navVisible)
    const navMobileContainer = document.getElementById("nav_mobile_container")
    if (navMobileContainer) {
      if (!navVisible) {
        navMobileContainer.classList.remove("slideIn")
        navMobileContainer.classList.add("slideOut")

      } else {
        navMobileContainer.classList.remove("slideOut")
        navMobileContainer.classList.add("slideIn")
      }
    }
  }

  return (
    <div style={{ background: 'white',display: 'block', position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 9999 }}>
       <div className="slogan">
        Compras Colectivas Inteligentes. Comprando Unidos, Ahorramos Unidos
      </div>
        <Navbar />
        </div>
  )
}

export default Header
