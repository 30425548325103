import React from "react";
import '../styles/steps.css';
import { Link } from "react-router-dom";

function Steps() {
    return (
        <div className="steps">
            <div className="steps_container">
                <div className="step">
                    <p className="number">1</p>
                    <p className="title">Únete a Tu Colonia Gratis</p>
                    <p className="body">¡Muuy fácil, solo crea tu cuenta y Listo!<br></br>Dile a tus amigos y vecinos que también se unan, así TODOS obtienen mucho más ahorro.</p>
                </div>
                <div className="step">
                    <p className="number">2</p>
                    <p className="title">¡Súmate a las mejores promos!</p>
                    <p className="body">Conoce las ofertas. Haz tu pedido individual, nosotros unimos los pedidos y asi todos obtienen descuentos como si compraras al por mayor. Cuando se haya cumplido el mínimo de compradores o cuando se llegue a la fecha límite tu pedido será despachado.</p>
                </div>
                <div className="step">
                    <p className="number">3</p>
                    <p className="title">Comparte lo Bueno</p>
                    <p className="body">Dile a tus amigos y vecinos que se unan a las promociones, y así ahorrar más.</p>
                </div>
                <div className="step">
                    <p className="number">4</p>
                    <p className="title">Disfruta de una compra colectiva inteligente</p>
                    <p className="body">Puedes pagar con medios digitales o efectivo.<br></br>Recibies o retiras el producto o servicio. Y a disfrutar de tu ahorro.</p>
                </div>
            </div>
            <Link to='/register' className="button_steps">Regístrate</Link>
        </div>
    );
}

export default Steps;
