import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { linkCreaTuColonia } from "../constants/socialConstants";
import styled from 'styled-components';
import { TextParagraphNormal, TextTitle, TitleSection } from './texts';
import { ButtonCommon } from './buttons';


const CardContainer = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;
const SquareCard = styled(CardContainer)`

  height: 400px;
  margin-top: 20px;
  transition: transform 0.3s; /* Agrega una transición a la propiedad transform */
  @media (max-width: 1000px) {
    width: 100%!important;
    height: 300px;
  }
  &:hover{
    transform: scale(1.05); /* Reduce el tamaño del botón al 95% en hover */
  }
`;

const RectangularCard = styled(CardContainer)`
  width: 600px;
  height: 200px;
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`;

const ContainerCardContent = styled.div`
position: absolute;
bottom: 0;
left: 0;
width: 100%;

padding: 20px;
background: linear-gradient(to bottom, rgba(0, 0, 0,0), rgba(0, 0, 0, 0.9)); /* Degradado de arriba a abajo */
text-align: start;
`;

const Collage = () => {
    const cards = [
        { id: 1, name: 'Si perteneces a Tu Colonia, Viajar es más barato', description: 'Sé parte de Tu Colonia y pues ahorrar hasta $300 por galón.', image: '/images/collage/gasolinacategoria.png', trending: true, category: 'autos'},
        { id: 2, name: 'Sé parte de Tu Colonia  y ahorra en alimento para Tu Mascota', description: 'Si nos inscribimos 100 personas, todos obtendremos un descuento del 8% en productos de las marcas Monello, Select, Birbo y arena para gatos durante todo un año, ¡sin necesidad de pagar membresías! Además, disfrutaremos de un 5% de descuento en todas las demás marcas. ¡Aprovecha esta gran oportunidad!', image: '/images/collage/mascotascategoria.png', trending: false, category: 'mascotas' },
        { id: 3, name: 'Descuentos para darle vida a tus espacios', description: '¡No te lo Pierdas!', image: '/images/collage/hogarcategoria.png', trending: true, category: 'hogar' },
        { id: 4, name: 'Tecnología', description: 'En Tu Colonia puedes ahorrar hasta 15% en lo último en tecnología', image: '/images/collage/tecnocategoria.png', trending: true, category: 'tecnologia' },
    ]

    const perteneceASecuencia = numero => {
        if (numero === 0) {
            return true; // El primer número de la secuencia es 0
        }

        let incremento = 3; // Comenzamos con un incremento de 3
        let valor = 0; // Inicializamos el valor en 0

        // Iteramos mientras el valor sea menor o igual al número dado
        while (valor <= numero) {
            if (valor === numero) {
                return true; // El número dado está en la secuencia
            }

            // Alternamos entre incrementos de 3 y 1
            if (incremento === 3) {
                valor += incremento; // Incremento de 3
                incremento = 1; // Cambiamos a incremento de 1 para el próximo paso
            } else {
                valor += incremento; // Incremento de 1
                incremento = 3; // Cambiamos a incremento de 3 para el próximo paso
            }
        }

        return false; // El número dado no está en la secuencia
    }


    return (
        <section id="collage" style={{ maxWidth: '1400px', margin: '80px auto', padding: '0 20px' }}>
            <TitleSection style={{ marginBottom: '20px' }}>Tendencia 🔥</TitleSection>
            <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between', gap: '10px', alignItems: 'space-between'  }}>
                {cards.map((card, index) => (
                    <SquareCard style={{ width: perteneceASecuencia(index) ? '30%' : '65%' }}>
                        <BackgroundImage src={card.image} alt="Card background" />
                        <ContainerCardContent>
                            <TextTitle style={{ color: 'white' }}>{card.name}</TextTitle>
                            <TextParagraphNormal style={{ color: 'white' }}>{card.description}</TextParagraphNormal>
                             {/* Enlace para redireccionar al componente de promociones con el parámetro de categoría */}
                             <Link to={`/promociones/${card.category}`}>
                                <ButtonCommon text='Únete aquí' typeForm='fill'/>
                            </Link>
                        </ContainerCardContent>
                    </SquareCard>

                ))}
            </div>
        </section>

    );
};




export default Collage;