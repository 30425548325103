import React, { useEffect } from 'react';
import styled from 'styled-components';
// Estilos del toast
const ToastContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${(props) => (props.type === 'error' ? 'red' : 'green')};
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  opacity: ${(props) => (props.visible ? '1' : '0')};
  transition: opacity 0.3s ease-in-out;
`;

const Toast = ({ message, type, visible, onClose }) => {
  // Cerrar el toast después de 3 segundos
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return <ToastContainer type={type} visible={visible}>{message}</ToastContainer>;
};

export default Toast;