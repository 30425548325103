import React from 'react';
import styled from 'styled-components';
import { TextParagraphNormal, TextTitle, TitleSection } from './texts';
import { Colors } from '../styles/global';
import { ButtonCommon } from './buttons';
import PopupForm from '../screens/WishPopUp';

// Contenedor principal del componente
const SectionWithBackground = styled.section`
  display: block;
  position: relative;
  width: 100%;
  min-height: 100vh; /* Asegura que el contenedor ocupe al menos toda la altura de la pantalla */
  padding: 0;
  margin-top: 100px;
`;

// Contenido del componente
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;

  /* Estilos para el contenido */
`;

const BackgroundSVG = styled.svg`
width: 100%;
height: auto; /* Cambiado de 100% a auto */

  `;

const ContainerCard = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background-color: ${Colors.quaternary};
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  `;

const ContainerCardImages = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      width: auto;
    }
  `;

const ContainerCardContent = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 10px 20px;
    @media (max-width: 768px) {
      width: 100%;
    }
  `;

// Componente principal
const Wish = ({ children }) => {
  return (
    <SectionWithBackground>
      <BackgroundSVG viewBox="0 0 100 9" preserveAspectRatio="none">
        {/* Curva 1 */}
        <path d="M0,5 Q25,0 50,5 T110,5 V10 H0 Z" fill={Colors.tertiary} />

      </BackgroundSVG>
      <div style={{ width: '100%', backgroundColor: Colors.tertiary, padding: '100px 0', margin:'0' }}>
        <Content>
          <TitleSection>¿NO ENCONTRASTE LA PROMO QUE BUSCABAS?</TitleSection>
          <TextTitle style={{ color: Colors.textAccent }} >Regístrate y Pide un deseo!</TextTitle>
          <ContainerCard>
            <ContainerCardImages>
              <img src='./images/wish_woman.png' style={{ width: '50%', height: 'auto' }} />
              <img src='./images/wish_boy.png' style={{ width: '50%', height: 'auto' }} />
            </ContainerCardImages>
            <ContainerCardContent>
              <TextParagraphNormal>Dinos los productos y servicios que te gustaría encontrar en Tu Colonia. Si mas personas los piden, obtendrás grandes ahorros. Escribenos contandonos sobre el producto que buscas. Nosotros hacemos el resto.</TextParagraphNormal>
              <PopupForm />
            </ContainerCardContent>
          </ContainerCard>

        </Content>

      </div>
      <BackgroundSVG viewBox="0 0 100 10" preserveAspectRatio="none">
        {/* Curva 1 */}
        <path d="M100,5 Q75,10 50,5 T0,5 V0 H100 Z" fill={Colors.tertiary} />
       
       </BackgroundSVG>

    </SectionWithBackground>
  );
};

export default Wish;
