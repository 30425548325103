import React, { useState, useEffect } from 'react'
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'
import HeaderClean from '../components/HeaderClean'
import Footer from '../components/Footer'
import '../styles/login.css';
import styled from 'styled-components';
import { Colors } from '../styles/global';
import { TextParagraphNormal, TextTitle, TitleSection } from '../components/texts';
import { login, loginAll } from '../actions/userActions'
import { ButtonCommon } from '../components/buttons';


const ContainerMain = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 130px;
`;
const LoginContainer = styled.div`
  width: 70%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    border-radius: 0px;
    box-shadow:none;
  }
`;
const ContainerForm = styled.div`
  width: 50vw;
  height: 100%;
  @media (max-width: 1080px) {
    width: 100%;
    height: auto;
  }
`;

const ContainerImg = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.quaternary};
  border-radius: 0 20px 20px 0;
  @media (max-width: 1080px) {
    display: none;
  }
`;

function LoginScreen() {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const dispatch = useDispatch()
  const dispatchAll = useDispatch()
  const match = useParams()
  const Location = useLocation()
  const navigate = useNavigate()
  const redirect = Location.search ? Location.search.split('=')[1] : '/'
  const userLogin = useSelector(state => state.userLogin)
  const { error, loading, userInfo } = userLogin
  const userAllLogin = useSelector(state => state.userAllLogin)
  const { errorA, loadingA, userInfoA } = userAllLogin

  useEffect(() => {
    if (userInfo) {
      dispatch(loginAll(email))
      navigate(redirect)
    }
  }, [navigate, userInfo, userInfoA, redirect])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, password))
  }

  return (
    <ContainerMain>
      <LoginContainer>

        <ContainerForm>
          <FormContainer>
            {error && <Message variant='danger'> {"No se encontró una cuenta activa para estas credenciales 😒"} </Message>}
            {loading && <Loader />}
            <Form onSubmit={submitHandler} className='login_form'>
              <Row>
              <TitleSection style={{ marginBottom: '50px' }}>Inicio de Sesión</TitleSection>
              </Row>
              <Form.Group controlId='email'>
                <Form.Label>E-mail </Form.Label>
                <Form.Control className='login_textfield' type='email' placeholder='📧 Ingresa tu correo eléctronico' value={email} onChange={(e) => setEmail(e.target.value)}></Form.Control>
              </Form.Group>
              <Form.Group className='mb-3' controlId='password'>
                <Form.Label>Contraseña </Form.Label>
                <Form.Control className='login_textfield' type='password' placeholder='🔑 Ingresa tu contraseña' value={password} onChange={(e) => setPassword(e.target.value)}></Form.Control>
              </Form.Group>
              <ButtonCommon  text={'Ingresar'} typeForm={'fill'}  type='submit' variant='primary'></ButtonCommon>

              
              <div className='password_reset_container'>
                <span className='login_span'>¿Olvidaste tu contraseña 😨?</span>
                {/* <a className='login_link' href="https://backend.tucolonia.co/api/users/accounts/password_reset/">Recuperala aquí</a> */}
                <a className='login_link' href="/password_reset_email">Recupera tu contraseña</a>
              </div>
              <div className='password_reset_container'>
              <Link  style={{ color: 'white', textAlign: 'start'}}  to={redirect ? `/register?redirect=${redirect}` : '/register'}><span className='login_span'>Aun no tienes cuenta? </span><span style={{ color: Colors.textAccent}}> Crea tu cuenta ahora</span></Link>
              </div>
             
            </Form>
          </FormContainer>
          
        </ContainerForm>
        <ContainerImg>
          <TextTitle style={{ color: 'white', textAlign: 'center', height: "20%", margin: "20px" }}>Estás a un paso de pertenercer a la mayor red de compras colectivas de Colombia.<br /> ¡Regístrate! </TextTitle>
          <img style={{ width: "100%", height: "80%" }} alt="registrate" src='./images/registro/login.svg' />
        </ContainerImg>
      </LoginContainer>


      <Footer />
    </ContainerMain>
  )
}

export default LoginScreen
