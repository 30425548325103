import styled from 'styled-components';
import { FaSearch } from 'react-icons/fa';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import React, { useState, useEffect, useRef } from 'react';
import OptionsTab from '../components/CategoryTab';
import { useParams } from 'react-router-dom';



const ContainerBodyPromociones = styled.div`
display: block;
position: relative;
width: 100%;
padding: 0;
margin-top: 120px;
`

const ContainerMainPromociones = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 20px;
`

const ContainerBarraBusqueda = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 50px;
    justify-content: space-between;
    
`

const ContainerImagesBarrraBusqueda = styled.img`
    width: 250px;
    height: auto;
    object-fit: cover;
    @media (max-width: 1050px) {
        display: none;
    }

`

//Estilos de la barra de busqueda
const SearchBarContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  flex: 1;
`;

const SearchIcon = styled(FaSearch)`
  color: green;
  margin-left: 10px;
`;

// Barra de categorias
const ContainerSeccionBarra = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;


const CategoriaItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
`;

const CategoriaIcono = styled.span`
  font-size: 24px;
`;

const CategoriaTitulo = styled.span`
  margin-top: 5px;
`;

const ContenedorCategoriaSeleccionada = styled.div`
  margin-top: 20px;
`;

const CategoriaBarra = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 10px;
`;

const ScrollButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 5px;
  cursor: pointer;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

const LeftScrollButton = styled(ScrollButton)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const RightScrollButton = styled(ScrollButton)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const PromocionesScreen = () => {

  const { category } = useParams();

  const [query, setQuery] = useState('');
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(null);
  const [scrollLeftVisible, setScrollLeftVisible] = useState(false);
  const [scrollRightVisible, setScrollRightVisible] = useState(false);
  const categoriaRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (categoriaRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = categoriaRef.current;
      setScrollLeftVisible(scrollLeft > 0);
      setScrollRightVisible(scrollWidth > clientWidth + scrollLeft);
    }
  }, [categoriaSeleccionada]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setQuery(inputValue);
    console.log(inputValue); // Esto muestra el valor en consola
  };

  const handleCategoriaClick = (categoria) => {
    setCategoriaSeleccionada(categoria);
  };

  const handleScroll = (scrollOffset) => {
    categoriaRef.current.scrollLeft += scrollOffset;
  };

  return (
    <ContainerBodyPromociones>
      <ContainerMainPromociones>
        <ContainerBarraBusqueda>
          <ContainerImagesBarrraBusqueda src="/images/promociones/personasComprasPromocionesBuscar1.svg" />

          <SearchBarContainer>
            <SearchInput
              type="text"
              placeholder="Buscar Producto..."
              value={query}
              onChange={handleInputChange}
            />
            <SearchIcon />
          </SearchBarContainer>

          <ContainerImagesBarrraBusqueda src="/images/promociones/personasComprasPromocionesBuscar2.svg" />

        </ContainerBarraBusqueda>

        <OptionsTab categoria={category ? category : 'autos'} />

      </ContainerMainPromociones>
    </ContainerBodyPromociones>
  )

}

export default PromocionesScreen;